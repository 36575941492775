import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet de peu molt prim, fibrós, llarg, peludet i del mateix color marró que el mamelló central del seu capell. Aquest mesura de 0,5 a 1,5 cm de diàmetre primer convex i després aplanat i de color ocre. Pot presentar els marges alçats. Les làmines estan separades, ventrudes i de color blanc. Les espores són blanques en massa, el·lipsoïdals, de 6-9 x 4-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      